import React from 'react'
import {Link} from 'gatsby'

class Breadcrumb extends React.Component {


  render() {
    return (
      <div style={{backgroundImage: `url(${this.props.background})`}} className="mt-bnr-inr overlay-wraper bg-parallax bg-top-center breadcontainer" data-stellar-background-ratio="0.5">
                  	<div className="overlay-main bg-black opacity-07"></div>
                      <div className="container">
                          <div className="mt-bnr-inr-entry">
                          	<div className="banner-title-outer">
                              	<div className="banner-title-name">
                              		<h2 className="m-b0">{this.props.title || "Creating quality urban lifestyles, building stronger communities." }</h2>
                                  </div>
                              </div>
                             {/*  <!-- BREADCRUMB ROW --> */}

                                  <div>
                                      <ul className="mt-breadcrumb breadcrumb-style-2">
                                      {this.props.path.map((item,i) => {
            return (

              <li key={i} className={(this.props.path.length === Number(i+1)) ? "breaditem active" : "breaditem"}>
                {(this.props.path.length === Number(i+1)) ? <span>{item.value.toUpperCase()}</span> :  <Link to={item.path}>{item.value.toUpperCase()}</Link>}

              </li>
            )
          })}
                                      </ul>
                                  </div>

                            {/*  <!-- BREADCRUMB ROW END --> */}
                          </div>
                      </div>
                  </div>
    )
  }
}

export default Breadcrumb
