import React from 'react'
import {Link, useStaticQuery, graphql } from 'gatsby'
import { navigate } from "gatsby"
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css'

class Header extends React.Component {

  onSelectFlag = (e) => {

    var selector = '';
    if(e === 'GB') {
      selector='EN'
    }
    if(e === 'GR') {
      selector='EL'
    }

    if(this.props.langcontainer) {
       this.props.langcontainer.forEach((item)=> {
         if(item.id === selector){
           navigate(item.path)
         }
       })
    }

  }
  render() {

    return (
      <header className="site-header header-style-1">
        <div className="top-bar bg-gray">
                <div className="container">
                    <div className="row">
                      <ReactFlagsSelect
                         countries={["GB", "GR"]}
                         onSelect={this.onSelectFlag}
                         defaultCountry={this.props.language === 'EN'? 'GB': 'GR'}


                         customLabels={{"GB": "EN","GR": "EL"}}
                         placeholder="Select Language" />
                       <div className="mt-topbar-left clearfix d-none d-md-block">
                            <ul className="list-unstyled e-p-bx pull-right ">
                                <li><i className="fa fa-envelope"></i> {this.props.info.fieldEmail}</li>
                                <li><i className="fa fa-phone"></i>{this.props.info.fieldPhone}</li>
                                {/*<li><i className="fa fa-clock-o"></i>Sun-Sat 9.45 am</li>  TODO working hours*/}
                            </ul>
                        </div>
                        <div className="mt-topbar-right clearfix ml-md-auto">
                            <div className="appint-btn"><Link to={`/${this.props.language.toLowerCase()}/contact`} className="site-button">
                              {this.props.language === 'EN'? 'CALL US': 'ΡΩΤΗΣΕ ΓΙΑ ΤΟ ΚΟΣΤΟΣ'}
                            </Link></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="sticky-header main-bar-wraper">
               <div className="main-bar bg-white">
                   <div className="container">


                       <nav className="nav navbar navbar-expand-lg p-0">
    <span className="navbar-brand" >
      <div className="logo-header">
          <div className="logo-header-inner logo-header-one">
              <Link to={`/${this.props.language.toLowerCase()}`}>
                  <img src={this.props.logourl} alt="logo" />
               </Link>
          </div>
      </div>
    </span>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="fas fa-bars"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarNav">
    <ul className="navbar-nav headernav">
      <li className={`nav-item ${this.props.path === '/'? 'active': null}`}>
        <Link className="nav-link" to={this.props.language === 'EL'? '/el': '/en'}>{this.props.language === 'EL'? 'Αρχικη' : 'Home'}</Link>
      </li>
      {this.props.menu.map((item,i) => {
        return (
          <li key={i} className={`nav-item ${this.props.path === item.url.path ? 'active': null}`}>
            <Link className="nav-link" to={item.url.path}>{item.label}</Link>
          </li>
        )
      })}


    </ul>
  </div>
</nav>










                   </div>
               </div>
           </div>

          </header>

        )
      }
    }


export default Header;
