import React from 'react'
import {Link} from 'gatsby'


class Footer extends React.Component {
  render () {
    var d = new Date();
    var n = d.getFullYear();

    return (
      <footer className="site-footer footer-large  footer-dark    footer-wide">

            {/*  FOOTER BLOCKES START */}
            <div className="footer-top overlay-wraper">
                <div className="overlay-main"></div>
                <div className="container">
                    <div className="row">
                        {/*  ABOUT COMPANY */}
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="widget widget_about">
                                {/* <h4 className="widget-title">About Company</h4>*/}
                                <div className="logo-footer clearfix p-b15">
                                  <img src={this.props.logourl} alt="footerLogo"/>
                                </div>
                                <p className="max-w400">
                                 {this.props.language === 'EN'? ' The company XYGKAKIS AGGELOS & SIA EE has been active for many years in the building. One of our most prominent activities for 20 years is the renting and placement of scaffolding.' :
                                 "Η εταιρεία ΞΥΓΚΑΚΗΣ ΆΓΓΕΛΟΣ & ΣΙΑ ΕΕ δραστηριοποιείται επί σειρά ετών στην οικοδομή. Μια εκ' των δραστηριοτήτων μας με κυρίαρχο ρόλο εδώ και 20 χρόνια είναι η ενοικίαση και τοποθέτηση ικριωματων."}
                                </p>

                                <ul className="social-icons  mt-social-links">
                                  {this.props.social.entities.map((item,i)=> {
                                    return (
                                      <li key={i}> <a className={item.fieldIcon} href={item.fieldLink}></a></li>
                                    )
                                  })}

                                </ul>
                            </div>
                        </div>

                        {/*  RESENT POST */}
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="widget widget_address_outer">
                                <h4 className="widget-title">{this.props.info.title}</h4>
                                <ul className="widget_address">
                                    <li>{this.props.info.fieldAddress}</li>
                                    <li>{this.props.info.fieldEmail}</li>
                                    <li>{this.props.info.fieldPhone}</li>

                                </ul>

                            </div>
                        </div>

                        {/*  USEFUL LINKS */}
                        <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                            <div className="widget widget_services inline-links">
                                <h4 className="widget-title">{this.props.language === 'EN'? "Useful links" : "χρησιμοι συνδεσμοι"}</h4>
                                <ul>
                                  {this.props.menu.map((item,i) => {
                                    return (
                                      <li key={i}>
                                        <Link to={item.url.path}>{item.label}</Link>
                                      </li>
                                    )
                                  })}

                                </ul>
                            </div>
                        </div>

                        {/*  TAGS */}

                        {/*  NEWSLETTER */}

                    </div>
                </div>
            </div>
            {/*  FOOTER COPYRIGHT */}
            <div className="footer-bottom overlay-wraper">
                <div className="overlay-main"></div>
                <div className="container">
                    <div className="row">
                        <div className="mt-footer-bot-center">
                            <span className="copyrights-text">{this.props.language === 'EN' ? `© ${n} XIGAKIS` :  `© ${n} ΞΥΓΚΑΚΗΣ` }</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

  }
}

export default Footer;
